/* You can add global styles to this file, and also import other style files */
@import "@fortawesome/fontawesome-free/css/all.css";

@import "mdb-angular-ui-kit/assets/scss/mdb.scss";
@import "mdb-angular-calendar/scss/calendar.scss";
@import "mdb-angular-parallax/scss/parallax.scss";
@import "mdb-angular-countdown/scss/countdown.scss";

@import "@glidejs/glide/src/assets/sass/glide.core";
@import "@glidejs/glide/src/assets/sass/glide.theme";

// Dark Theme
$sail-theme-primary: #1266f1;
$sail-theme-secondary: #9fa6b2;

$sail-dark-theme: mdb-dark-theme($sail-theme-primary, $sail-theme-secondary);

.dark {
    @include mdb-theme($sail-dark-theme);
}

// General Utilities
.pointer {
    cursor: pointer !important;
}

.hoverUnderline:hover {
    text-decoration: underline;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

// Carousel
.glide--swipeable {
    cursor: unset;
}
